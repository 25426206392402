.scope-container {
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
	padding: 20px;
	margin: 15px;
}

.ant-table {
	//box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
	margin: 15px;
	margin-bottom: 30px;

	& table,
	th,
	td {
		border: 1px solid #e8e8e8;
		border-collapse: collapse;
	}
}

.ant-table-row > td {
	text-align: center;
	position: relative;

	& p {
		text-align: left;
	}
}

.risk-table {
	margin-bottom: 5px;
}

.summary-container {
	/*margin: 15px;*/
}

.risk-table tr td {
	position: static;
	border: 1px solid #e8e8e8;
}

.threat-legend div:nth-child(1) {
	text-align: right;
	margin-top: -5px;
}

.threat-legend div:nth-child(2) {
	margin-top: 19px;
}

.threat-legend div:nth-child(1) span:nth-child(1) {
	position: relative;
	top: 9px;
}

.threat-table tr {
	height: 60px !important;
}

.threat-table tr td:nth-child(1) {
	text-align: left;
	padding-left: 71px;
}

.threat-table tr td {
	padding: 0;
}

.threat-field {
	background-color: #e7e7e7;
	margin: 15px;
	margin-bottom: 30px;
	padding-top: 10px;
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.form-check-input {
	bottom: 20px;
	margin-left: -8px;
}

.form-check-input2 {
	// top: 80%;
	bottom: 20px;
	margin-left: -8px;
}

.ant-table-thead > tr {
	height: 96px;
}

.ant-table-thead > tr > th {
	text-align: center;
}

.ant-table-tbody {
	background-color: #fafafa;
}

.ant-table-column-title > input {
	margin-left: -8px;
}

.infoButton {
	position: relative;
	font-size: 30px;
	float: right;
	background-color: white !important;
	border: none !important;
	box-shadow: none !important;
	z-index: 1000;
	margin-top: -30px;
	border-radius: 50px;
	margin-bottom: 7px;
}

.infoButton > i {
	color: #63c2de;
}

.ant-popover-title {
	text-transform: capitalize;
}

.likelihood-tab-table tr {
	height: 112px;
}

.likelihood-tab-table input {
	top: -2px;
	position: relative;
	vertical-align: top;
}

.likelihood-tab-table th span div {
	padding: 10px;
	padding-bottom: 0;
	border-radius: 5px;
	width: 80px;
	height: 62px;
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.likelihood-tab-table th:nth-child(6) span div {
	background-color: #f63c3a;
}

.likelihood-tab-table th:nth-child(5) span div {
	background-color: #f88f2a;
	line-height: 3;
}

.likelihood-tab-table th:nth-child(4) span div {
	background-color: #ffc107;
	line-height: 3;
}

.likelihood-tab-table th:nth-child(3) span div {
	background-color: #20a8d8;
	line-height: 3;
}

.likelihood-tab-table th:nth-child(2) span div {
	background-color: #4dbd74;
}

.impact-tab-table {
	& span div {
		padding: 10px;
		padding-bottom: 0;
		border-radius: 5px;
		box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
		width: 80px;
	}

	& th:nth-child(1) span div {
		box-shadow: none;
	}

	& th:nth-child(2) span div {
		background-color: #4dbd74;
	}

	& th:nth-child(3) span div {
		background-color: #20a8d8;
	}

	& th:nth-child(4) span div {
		background-color: #ffc107;
	}

	& th:nth-child(5) span div {
		background-color: #f88f2a;
	}

	& th:nth-child(6) span div {
		background-color: #f63c3a;
	}
}

//.threat-tab-table {
//
//  & th span div {
//	padding: 10px;
//	padding-bottom: 0;
//	border-radius: 5px;
//	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
//  }
//
//  & th:nth-child(1) span div {
//	box-shadow: none;
//  }
//
//  & th:nth-child(2) span div {
//	background-color: #4dbd74;
//  }
//
//  & th:nth-child(3) span div {
//	background-color: #20a8d8;
//  }
//
//  & th:nth-child(4) span div {
//	background-color: #ffc107;
//  }
//
//  & th:nth-child(5) span div {
//	background-color: #f88f2a;
//  }
//
//  & th:nth-child(6) span div {
//	background-color: #f63c3a;
//  }
//
//  & th:nth-child(6) span div {
//	background-color: #f63c3a;
//  }
//}

.threat-bulletClass {
	font-size: 40px;
}

.threat-cubeClass {
	font-size: 20px;
}

.card.bg-warning .card-header {
	background-color: #15435b;
	border-color: #c69500;
}

.bg-warning {
	background-color: #296787 !important;
}

.sidebar {
	background: #118b4c !important;
  .nav-link{
	.nav-icon{
	  color:white;
	}
  }
  .nav-link.active{
	.nav-icon {
	  color: #118b4c;
	}
  }
  .nav-link:hover{
	background: #0e713e;
  }
}

label {
	margin-bottom: 0;
}

.ant-form-item-label {
	line-height: 26px;
}

.ant-form-inline .ant-form-item {
	width: 163px;
	margin-right: 0;
}

.card.bg-warning {
	/*box-shadow: 0 0 10px #393939;*/
}

hr {
	margin-top: 10px;
	margin-bottom: 5px;
}

.selected-threat {
	background: #f88f2a;
}

.btn-ghost-dark {
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	box-shadow: none !important;
}

.threatButtonContainer {
	display: flex;
	margin-bottom: 20px;
	flex-wrap: wrap;
}

.threat-point-container > form > div > div:nth-child(1) {
	text-align: left;
	padding-left: 22px;
}

.ant-form-item:nth-child(6) .ant-input-number-disabled {
	width: 40px;
	text-align: center;
	margin-left: 20px;
	color: rgb(0, 0, 0);
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.risk-legend-container {
	display: flex;
	margin-left: 30px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.risk-legend {
	width: 70px;
	height: 20px;
	border-radius: 10px;
	margin-right: 5px;
}

.scoreStyle {
	width: 50px;
	height: 50px;
	border-radius: 5px;
	line-height: 3.5;
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.total-score {
	margin-top: 20px;
	margin-bottom: 20px;
	width: 100%;
	margin-left: 30px;
	margin-right: 30px;
	padding: 10px;
	border-radius: 5px;
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
	text-align: center;
	font-size: 1.1em;
	color: white;
}

.summary-colored-backgrounds {
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	padding: 8px;
	//box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.summary-container > .btn-group {
	margin: 15px;
}

.login-card {
	border: 3px solid #c69500 !important;

	& > div {
		border-radius: 0;
		border: none;
	}

	.bg-primary {
		background-color: #296787 !important;
	}
}

.rm-risk-container {
	display: flex;
	justify-content: center;
	align-items: center;
	line-height: 20px;

	div {
		margin-right: 15px;
	}

	.rm-colored-backgrounds {
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		border-radius: 5px;
		padding: 8px;
		box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
		height: 40px;
		width: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.summary-container {
	.ant-table-body {
		padding-bottom: 25px;
	}
}
.innerButtonContainer {
	border-right: 1px solid rgba(0, 0, 0, 0.1);

	button {
		color: #4d4b4b;
	}
}

.threat-title {
	font-size: 25px;
	color: #b5b3b3;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.risk-management {
	hr {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}
}

.rm-risk-container:nth-child(1) {
	background-color: rgba(0, 0, 0, 0.1);
	margin-top: -16px;
	margin-bottom: -16px;
	margin-left: 16px;
	color: white;
	font-size: 18px;
}
.app-header {
	h4 {
		width: 40rem;
	    color:#118b4c;
	  	font-weight: 400;
	}
}

.help-content-container {
	.input-group-prepend {
		flex: 0.2;

		:last-child {
			flex: 1;
			text-align: left;
		}
	}
}

.app-header.navbar {
  background-color: white;
}
.app-header{
  .navbar-brand{
	width: 120px;
  }

}
.sidebar .sidebar-footer {
	background-color: #3b484e;
	text-align: center;
	cursor: pointer;
}
.tutorial-video {
	width: 100%;
}
.modal-info .modal-content {
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.modal-content {
	border: unset;
}
.modal-info .modal-content {
	border-color: #63c2de;
}
