.home-page {
	.hero-content {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.slogan {
			color: #118b4c;
			/* text-shadow: 1px 1px 10px #000; */
			font-size: 30px;
			line-height: 60px;
			flex-basis: auto !important;
			text-align: left;
			width: 100%;
			background-color: #f0f3f5;
			padding-left: 2em;
			padding-right: 2em;
			padding-bottom: 1em;
			padding-top: 1em;
			border-top: 0.6rem solid #118b4c;
			text-align: center;
			box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.3);

			p {
				font-size: 24px;
				line-height: 40px;
				padding-top: 15px;
				color: white;
			}
			.heading {
				margin-bottom: 0.5em;
				img {
					width: 12em;
				}
			}
			.title-container {
				justify-content: center;
			}
		}
	}

	.headroom {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		transition: all 0.2s ease-in-out;
		background-color: #5c7680;
		z-index: 2;

		&--not-top {
			background-color: #5c7680;
			padding-top: 0;
			padding-bottom: 0;
		}

		&--top {
			background-color: transparent;
			padding-top: 1.5em;
			color: black !important;
		}

		&--pinned {
			top: 0;
		}

		&--unpinned {
			top: 0;
		}

		&.navbar {
			padding-left: 4em;
			padding-right: 4em;

			.navbar-brand,
			.nav-link {
				color: white !important;
			}
			.navbar-brand > img {
				height: 6em;
				padding-right: 0.5em;
			}
			.nav-item {
				.nav-link {
					display: flex;
					flex-direction: row;
					align-items: center;
					padding-right: 0.5em;
				}
				.user-name {
					padding-left: 0.5em;
				}
			}
			.navbar-light .navbar-nav .nav-link {
				color: #f0f3f5;
			}
			.navbar-brand {
				font-size: 1.2em;
			}
			.navbar-light .navbar-brand {
				color: #f0f3f5 !important;
			}
			.navbar-nav {
				flex-direction: row;
				justify-content: center;
				align-items: center;
			}
			.navbar-brand:hover {
				color: white !important;
			}
			.nav-link:hover {
				color: white !important;
			}
			.img-avatar {
				height: 32px !important;
				border-radius: unset;
			}
			.navbar-light .navbar-nav .nav-link.active {
				color: white !important;
			}
			.nav-link > i {
				font-size: 20px;
				padding-right: 0.3em;
			}
			.navbar .px-3 {
				padding-left: 0 !important;
			}

			li {
				list-style: none;
			}
		}
	}
}

/////AKDN Colors
.btn-akdn-primary {
	@include button-variant(#118b4c, #118b4c);
	color: $white;
	height: 5rem;
	font-size: 1.5rem;
	line-height: 4rem;
}

.btn-akdn-secondary {
	@include button-variant(#1a3e49, #1a3e49);
	color: $white;
	height: 5rem;
	font-size: 1.5rem;
	line-height: 4rem;
}

.btn-akdn-warning {
	@include button-variant($orange, $orange);

	@include hover {
		color: white;
	}

	color: $white;
	height: 5rem;
	font-size: 1.5rem;
	line-height: 4rem;
}

.parallax-inner {
	filter: brightness(0.6);
}
